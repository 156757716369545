import React from 'react';
import { ThemeProvider, createTheme, Stack, Grid, Box, Typography } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const theme = createTheme({
  typography: {
    fontFamily: 'Varta',
  },
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

interface AccordianGroupProps{
    Title: string;
    Colour: string;
    FontColour?: string;
    Child?: JSX.Element;
    handleClick(): void;
};

export const AccordianGroup = ({ Title, Colour, FontColour = "primary", Child, handleClick }: AccordianGroupProps) => {

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Box onClick={handleClick} sx={{backgroundColor:Colour,borderBottom:"solid 1px #666666",height:40}}>
          <Grid container>
            <Grid item xs sx={{paddingLeft:"10px"}}>
              <Typography variant="h6" color={FontColour} sx={{lineHeight:'40px'}}>{Title}</Typography>
            </Grid>
            <Grid item xs="auto" sx={{paddingRight:"10px"}}>
              <Box sx={{height:"100%",display:"flex",alignItems:"center"}}>
                <ExpandCircleDownIcon color="primary" fontSize="large" {...(Child === undefined ? {} : {
                  sx:{transform:"rotate(180deg)"}
                })} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {Child !== undefined && Child}
      </Stack>
    </ThemeProvider>
  );

};
