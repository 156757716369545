import React from 'react';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme, Stack, Box, Typography, Button } from '@mui/material';

import { ErrorContext } from '../contexts/error-context';

const theme = createTheme({
  typography: {
    fontFamily: 'Sofia Sans, Varta',
  },
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

export interface ErrorProps{
  errorTitle: string;
  errorText: string;
};

export const ErrorScreen = () => {

  const ErrorMsg = React.useContext(ErrorContext);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height:"100%",
        width:"100%",
        maxWidth:"400px",
        margin:"auto"
      }}>
        <Stack>
          <Box className="hotpay_logo_container">
            <img alt="HotPay" src="../hotpay_white_269x125.svg" style={{maxWidth:"200px"}} />
          </Box>
          <Box sx={{paddingLeft:"5%",paddingRight:"5%"}}>
            <Typography variant="h1" color="primary" align="center">!!!</Typography><br /><br />
            <Typography variant="h5" color="primary" align="center">{ErrorMsg.Title}</Typography><br /><br />
            <Typography variant="h6" color="primary" align="center">{ErrorMsg.Text}</Typography><br /><br />
            <Box sx={{textAlign:'center',width:'100%'}}>
              <Button onClick={handleGoBack} variant='contained' color='primary'>
                <Typography variant="h6" align="center" sx={{fontWeight:'bold'}}>GO BACK</Typography>
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );

};
