import React from 'react';

export interface HotpayEmployer{
  ID: string;
  Name: string;
};

export const EmployerDefault: HotpayEmployer = {
  ID: "",
  Name: "",
};

export const EmployerContext = React.createContext<HotpayEmployer>(EmployerDefault);
