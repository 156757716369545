import React from 'react';
import { ThemeProvider, createTheme, Box, CircularProgress } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Varta',
  },
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

export const LoadingIndicator = () => {

  return (
      <ThemeProvider theme={theme}>
        <Box sx={{
          height:"100%",
          width:"100%",
          maxWidth:"400px",
          margin:"auto"
        }}>
          <Box sx={{width:"50%",margin:"auto",marginTop:"calc(100vh / 4)"}}>
            <CircularProgress color="primary" size="100%" />
          </Box>
        </Box>
      </ThemeProvider>
  );

};
