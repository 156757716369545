import React from 'react';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme, Stack, Box, Typography, Grid, Button, Select, MenuItem, SelectChangeEvent, Checkbox, TextField } from '@mui/material';

import { UserContext } from '../contexts/user-context';
import { TimesheetContext } from '../contexts/timesheet-context';

import { LoadingIndicator } from '../components/loading-indicator';
import { Header } from '../components/header';
import { AccordianGroup } from '../components/accordian-group';

const TIMESHEET_NOTES_BOX_ID = "B3D98722-27BB-11EE-BCF8-DC84027D6EB0";

const theme = createTheme({
  typography: {
    fontFamily: 'Varta',
  },
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

export const TimesheetEntry = ({
  handleError,
}: {
  handleError({errorTitle, errorText}: {errorTitle: string, errorText: string}): void;
}) => {

  const weekdayNames = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  const User = React.useContext(UserContext);
  const Timesheet = React.useContext(TimesheetContext);

  const allAccordians = [
    ...Timesheet.Checksheets.map((Checksheet) => {
      return Checksheet.ID;
    }),
    TIMESHEET_NOTES_BOX_ID,
  ]

  const [loadingInProgress, setLoading] = React.useState<Boolean>(false);
  const [selectedAccordians, setSelectedAccordians] = React.useState<string[]>(allAccordians);

  const expandAccordian = (accordian: string) => {
    const newSet = [
      ...selectedAccordians,
      accordian,
    ];
    setSelectedAccordians(newSet);
  };

  const collapseAccordian = (accordian: string) => {
    const newSet = selectedAccordians.filter((existing) => {
      return existing !== accordian;
    });
    setSelectedAccordians(newSet);
  };

  const navigate = useNavigate();

  const formatTime = (inputDate: Date | null) => {
    if(!inputDate){
      return "";
    }
    const hours = (inputDate.getHours() > 12 ? (inputDate.getHours() - 12) : inputDate.getHours());
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const ampm = (inputDate.getHours() > 11 ? "pm" : "am");
    return `${(hours > 0 ? hours : "12")}:${minutes}${ampm}`;
  };

  const formatTimeBackend = (inputDate: Date) => {
    const hours = inputDate.getHours().toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}:00`;
  };

  const createDate = (inputDate: string): Date => {
    const dateArray = inputDate.split(/[- :]/);
    return new Date(
      Number(dateArray[0]),
      Number(dateArray[1]) - 1,
      Number(dateArray[2]),
      Number(dateArray[3]),
      Number(dateArray[4]),
      Number(dateArray[5])
    );
  };

  const checkedItemIDs = Timesheet.Checksheets.flatMap((Checksheet) => {
    return Checksheet.Items.flatMap((Item) => {
      if(Item.Ticked){
        return Item.ID;
      }
      return [];
    });
  });

  const [enteredStartTime, setEnteredStartTime] = React.useState<string>(formatTimeBackend(Timesheet.StartTime));
  const [enteredEndTime, setEnteredEndTime] = React.useState<string>(Timesheet.EndTime ? formatTimeBackend(Timesheet.EndTime) : "");
  const [selectedChecksheetItems, setSelectedChecksheetItems] = React.useState<string[]>(checkedItemIDs);
  const [enteredNote, setEnteredNote] = React.useState<string>("");

  const enteredStartTimeAsDate = createDate(
    Timesheet.StartTime.getFullYear()+"-"+
    (Timesheet.StartTime.getMonth() + 1).toString().padStart(2, "0")+"-"+
    Timesheet.StartTime.getDate().toString().padStart(2, "0")+" "+
    enteredStartTime
  );

  const now = new Date();

  const simulatedNowSameDay = new Date(
    enteredStartTimeAsDate.getFullYear(),
    enteredStartTimeAsDate.getMonth(),
    enteredStartTimeAsDate.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  );

  const simulatedNow = (simulatedNowSameDay < enteredStartTimeAsDate ? new Date(
    enteredStartTimeAsDate.getFullYear(),
    enteredStartTimeAsDate.getMonth(),
    enteredStartTimeAsDate.getDate()+1,
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  ) : simulatedNowSameDay);

  let time_options: Date[] = [];
  for(let i=0; i<96; i++){
      time_options[i] = new Date(2023, 1, 1, 0, (i*15));
  }

  let time_options_end: Date[] = [];
  let set_default_index: number = 0;
  for(let j=0; j<96; j++){
    time_options_end[j] = new Date(
      enteredStartTimeAsDate.getFullYear(),
      enteredStartTimeAsDate.getMonth(),
      enteredStartTimeAsDate.getDate(),
      enteredStartTimeAsDate.getHours(),
      enteredStartTimeAsDate.getMinutes() + (j*15)
    );
    if(time_options_end[j] > simulatedNow && set_default_index < 1){
      set_default_index = (j - 1);
    }
  }

  if(set_default_index > 0 && enteredEndTime === ""){
    setEnteredEndTime(formatTimeBackend(time_options_end[set_default_index]));
  }

  const isLocked = (Timesheet.Status === "APP");

  const handleSelectChecksheetItem = (ItemID: string) => {
    if(!isLocked){
      if(selectedChecksheetItems.includes(ItemID)){
        setSelectedChecksheetItems(selectedChecksheetItems.filter((item) => {return item !== ItemID}));
        return;
      }
      setSelectedChecksheetItems([...selectedChecksheetItems, ItemID]);
    }
  };

  const handleAddNotes = (e: any) => {
    setEnteredNote(e.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    fetch("https://admin.hotpay.com.au/api/post-timesheet", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: (
        'key='+User.AuthKey+
        '&pin='+User.AuthPin+
        '&timesheet='+Timesheet.ID+
        '&start_time='+enteredStartTime+
        '&end_time='+enteredEndTime+
        selectedChecksheetItems.reduce((acc, item, index) => {
          return acc+"&checked_item_id["+index+"]="+item
        }, "")+
        '&notes='+enteredNote
      ),
    }).then(response => response.json()).then(json => {
      if(!json.auth.ok){
        handleError({
          errorTitle: "Invalid Login",
          errorText: "Please ask your Employer to resend your HotPay link.",
        });
      }
      if(!json.result.ok){
        handleError({
          errorTitle: "Something went wrong",
          errorText: json.result.message,
        });
      }
      navigate('/timesheets/list');
    }).catch((error) => {
      handleError({
        errorTitle: "Something went wrong",
        errorText: "Timesheet failed to submit because: "+error,
      });
    });
  };

  if(loadingInProgress){
    return (
      <LoadingIndicator />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height:"100%",
        width:"100%",
        maxWidth:"400px",
        margin:"auto"
      }}>
        <Stack>
          <Header />
          <Box sx={{borderBottom:"solid 1px #666666",backgroundColor:"#AAAAAA",height:60}}>
            <Typography variant="body2" align="center" sx={{lineHeight:'20px',marginTop:'10px',marginBottom:'-10px'}}>{weekdayNames[Timesheet.StartTime.getDay()]}</Typography>
            <Typography variant="h6" align="center" sx={{lineHeight:'42px',fontWeight:'bold'}}>{Timesheet.StartTime.getDate().toString().padStart(2, "0")}-{monthNames[Timesheet.StartTime.getMonth()]}-{Timesheet.StartTime.getFullYear()}</Typography>
          </Box>
          <Box sx={{borderBottom:"solid 1px #666666",backgroundColor:"#EEEEEE",width:"100%",overflowY:"scroll"}}>
          <Grid container>
            <Grid item xs="auto">
              <Box sx={{padding:'20px'}}>
                <Typography variant="body2" align="center">START TIME</Typography>
                <Select readOnly={isLocked} id="outlined-basic" variant="outlined" sx={{backgroundColor:'#FFFFFF',textAlign:'center',width:'120px'}} value={enteredStartTime} onChange={(event: SelectChangeEvent) => setEnteredStartTime(event.target.value)}>
                  <MenuItem value={""}></MenuItem>
                  {time_options.map((to) => {
                    return (
                      <MenuItem value={formatTimeBackend(to)}>{formatTime(to)}</MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
            <Grid item xs>
              <Box sx={{paddingTop:'50px'}}>
                <Typography variant="h6" align="center" sx={{fontWeight:'bold'}}>to</Typography>
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Box sx={{padding:'20px'}}>
                <Typography variant="body2" align="center">FINISH TIME</Typography>
                <Select readOnly={isLocked} id="outlined-basic" variant="outlined" sx={{backgroundColor:'#FFFFFF',textAlign:'center',width:'120px'}} value={enteredEndTime} onChange={(event: SelectChangeEvent) => setEnteredEndTime(event.target.value)}>
                  <MenuItem value={""}></MenuItem>
                  {time_options_end.map((to) => {
                    return (
                      <MenuItem value={formatTimeBackend(to)}>{formatTime(to)}</MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
          </Grid>
          </Box>
          <Box sx={{height:'calc(100vh - 387px)',overflowY:'scroll',backgroundColor:'#EEEEEE'}}>
            {Timesheet.Checksheets.map((Checksheet) => {
              return (
                <AccordianGroup Title={Checksheet.Name} Colour="#BFBFBF" FontColour="#000000" {...(selectedAccordians.includes(Checksheet.ID) ? {
                  Child: (
                    <Box sx={{backgroundColor:"#EEEEEE",width:"100%",overflowY:"scroll"}}>
                      <Stack>
                        {Checksheet.Items.map((Item) => {
                          return (
                            <Box onClick={() => handleSelectChecksheetItem(Item.ID)} sx={{paddingTop:'3px'}}>
                              <Grid container>
                                <Grid item xs="auto" sx={{paddingLeft:"10px"}}>
                                  <Checkbox readOnly={isLocked} sx={{'&.Mui-checked': {color: '#000000'}}} checked={selectedChecksheetItems.includes(Item.ID)} />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="h6" sx={{lineHeight:'47px'}}>{Item.Name}</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          )
                        })}
                      </Stack>
                    </Box>
                  ),
                  handleClick: () => collapseAccordian(Checksheet.ID)
                } : {
                  handleClick: () => expandAccordian(Checksheet.ID)
                })} />
              );
            })}
            <AccordianGroup Title="Notes" Colour="#BFBFBF" FontColour="#000000" {...(selectedAccordians.includes(TIMESHEET_NOTES_BOX_ID) ? {
              Child: (
                <Box sx={{backgroundColor:"#EEEEEE",width:"100%",overflowY:"scroll"}}>
                  <Stack>
                    {Timesheet.Notes.map((Note) => {
                      const label = Note.Timestamp.getFullYear()+"-"+(Note.Timestamp.getMonth() + 1).toString().padStart(2, "0")+"-"+Note.Timestamp.getDate().toString().padStart(2, "0")+" by "+Note.AuthorName;
                      return (
                        <Box sx={{padding:'20px',paddingBottom:'0px'}}>
                          <TextField variant='outlined' multiline disabled sx={{width:'100%'}} label={label} value={Note.Text} />
                        </Box>
                      );
                    })}
                    <Box sx={{padding:'20px'}}>
                      <TextField onChange={handleAddNotes} variant='outlined' multiline minRows={3} sx={{width:'100%'}} label='Add notes to this timesheet' />
                    </Box>
                  </Stack>
                </Box>
              ),
              handleClick: () => collapseAccordian(TIMESHEET_NOTES_BOX_ID)
            } : {
              handleClick: () => expandAccordian(TIMESHEET_NOTES_BOX_ID)
            })} />
          </Box>
          <Box sx={{backgroundColor:"#EEEEEE",padding:"20px",borderTop:"solid 1px #666666"}}>
            <Button disabled={isLocked} onClick={handleSubmit} variant='contained' color='success' sx={{width:'100%'}}><Typography variant="h6" align="center" sx={{fontWeight:'bold'}}>SUBMIT TIMESHEET</Typography></Button>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );

};
