import React from 'react';
import { Stack, Grid, Box, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { HotpayTimesheet } from '../types/timesheet';

interface TimesheetTileProps{
  Timesheet: HotpayTimesheet;
  handleClick(Timesheet: HotpayTimesheet): void;
};

export const TimesheetTile = ({ Timesheet, handleClick }: TimesheetTileProps) => {

  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  const formatTime = (inputDate: Date | null) => {
    if(!inputDate){
      return "TBA";
    }
    const hours = (inputDate.getHours() > 12 ? (inputDate.getHours() - 12) : inputDate.getHours());
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const ampm = (inputDate.getHours() > 11 ? "pm" : "am");
    return `${(hours > 0 ? hours : "12")}:${minutes}${ampm}`;
  };

  return (
    <Box sx={{borderBottom:"solid 1px #666666"}} onClick={() => handleClick(Timesheet)}>
    <Grid container>
      <Grid item xs="auto">
        <Box sx={{height:80,width:80,backgroundColor:"#CCCCCC",borderRadius:"5px",margin:"10px",paddingTop:"10px"}}>
          <Typography variant="body2" color="#444444" align="center">{monthNames[Timesheet.StartTime.getMonth()]}</Typography>
          <Typography variant="h3" color="#444444" align="center">{Timesheet.StartTime.getDate()}</Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Box sx={{height:"100%",display:"flex",alignItems:"center"}}>
        <Grid container>
        <Grid item xs="auto">
            <Stack>
            <Typography variant="body2">Name:&nbsp;&nbsp;</Typography>
            <Typography variant="body2">Project:&nbsp;&nbsp;</Typography>
            <Typography variant="body2">Start/End:&nbsp;&nbsp;</Typography>
            <Typography variant="body2">Hours:&nbsp;&nbsp;</Typography>
            </Stack>
        </Grid>
        <Grid item xs>
            <Stack>
            <Typography variant="body2">{Timesheet.User.Name}</Typography>
            <Typography variant="body2">{Timesheet.Project.Name}</Typography>
            <Typography variant="body2">{formatTime(Timesheet.StartTime)} to {formatTime(Timesheet.EndTime)}</Typography>
            <Typography variant="body2">{(Timesheet.Hours !== null ? Timesheet.Hours.toFixed(2) : "TBA")}</Typography>
            </Stack>
        </Grid>
        </Grid>
        </Box>
      </Grid>
    <Grid item xs="auto">
      <Box sx={{height:"100%",display:"flex",alignItems:"center"}}>
        <ArrowForwardIosIcon fontSize="large" />
      </Box>
    </Grid>
    </Grid>
    </Box>
  );

};
