import React, { useEffect } from 'react';
import { ThemeProvider, createTheme, Stack, Box } from '@mui/material';

import { HotpayTimesheet } from '../types/timesheet';
import { HotPayTimesheetStatus } from '../types/timesheet-status';

import { HotpayEmployer } from '../contexts/employer-context';
import { UserContext } from '../contexts/user-context';
import { HotpayProject } from '../contexts/project-context';

import { LoadingIndicator } from '../components/loading-indicator';
import { Header } from '../components/header';
import { AccordianGroup } from '../components/accordian-group';
import { TimesheetTile } from '../components/timesheet-tile';

const theme = createTheme({
  typography: {
    fontFamily: 'Varta',
  },
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

export const TimesheetList = ({
  handleError,
  handleEmployer,
  handleProject,
  handleTimesheet,
}: {
  handleError({errorTitle, errorText}: {errorTitle: string, errorText: string}): void;
  handleEmployer(Employer: HotpayEmployer): void;
  handleProject(Project: HotpayProject): void;
  handleTimesheet(Timesheet: HotpayTimesheet): void;
}) => {

  const User = React.useContext(UserContext);

  const [loadingInProgress, setLoading] = React.useState<Boolean>(true);
  const [Timesheets, setTimesheets] = React.useState<HotpayTimesheet[]>([]);
  const [selectedAccordian, setSelectedAccordian] = React.useState<HotPayTimesheetStatus | null>("DRAFT");

  const createDate = (inputDate: string): Date => {
    const dateArray = inputDate.split(/[- :]/);
    return new Date(
      Number(dateArray[0]),
      Number(dateArray[1]) - 1,
      Number(dateArray[2]),
      Number(dateArray[3]),
      Number(dateArray[4]),
      Number(dateArray[5])
    );
  };

  useEffect(() => {
    const computeStatusString = (submitted: boolean, approved: boolean, starttime: Date | null) => {
      if(approved){
        return "APP";
      }
      if(submitted){
        return "UNAPP";
      }
      if(starttime){
        const now = new Date();
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23, 59, 59
        );
        if(starttime > today){
          return "FUT";
        }
      }
      return "DRAFT";
    };
    fetch("https://admin.hotpay.com.au/api/timesheets", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'key='+User.AuthKey+'&pin='+User.AuthPin,
    }).then(response => response.json()).then(json => {
      if(!json.auth.ok){
        handleError({
          errorTitle: "Invalid Login",
          errorText: "Please ask your Employer to resend your HotPay link.",
        });
      }
      else if(!json.timesheets){
        handleError({
          errorTitle: "No Timesheets",
          errorText: "You haven't been assigned any timesheets.",
        });
      }
      else if(json.timesheets.length < 1){
        handleError({
          errorTitle: "No Timesheets",
          errorText: "You haven't been assigned any timesheets.",
        });
      }
      else{
        handleEmployer({
          ID: json.timesheets[0].employer.id,// TODO: just using the first in list
          Name: json.timesheets[0].employer.name,
        });
        handleProject({
          ID: json.timesheets[0].project.id,// TODO: just using the first in list
          Name: json.timesheets[0].project.name,
        });
        setTimesheets(json.timesheets.map((timesheet: any) => {
          return {
            ID: timesheet.id,
            User: {
              ID: timesheet.user.id,
              Name: timesheet.user.name,
            },
            Employer: {
              ID: timesheet.employer.id,
              Name: timesheet.employer.name,
            },
            Project: {
              ID: timesheet.project.id,
              Name: timesheet.project.name,
            },
            StartTime: (timesheet.start_time ? createDate(timesheet.start_time) : null),
            EndTime: (timesheet.end_time ? createDate(timesheet.end_time) : null),
            Hours: timesheet.ts_hours,
            Status: computeStatusString(
              timesheet.is_submitted,
              timesheet.is_approved,
              (timesheet.start_time ? createDate(timesheet.start_time) : null),
            ),
            Checksheets: timesheet.checkgroups ? timesheet.checkgroups.map((checkgroup: any) => {
              return {
                ID: checkgroup.id,
                Name: checkgroup.name,
                Items: checkgroup.items.map((item: any) => {
                  return {
                    ID: item.checkitem_id,
                    Name: item.checkitem_name,
                    Ticked: (item.checkitem_ticked > 0),
                  }
                }),
              }
            }) : [],
            Notes: timesheet.notes ? timesheet.notes.map((note: any) => {
              return {
                ID: note.id,
                Timestamp: createDate(note.timestamp),
                Text: note.note_text,
                AuthorName: note.person_name,
              }
            }) : [],
          }
        }));
        setLoading(false);
      }
    });
  }, [/*handleError, handleEmployer, handleProject, TO: fix this dependancy without loop */User.AuthKey, User.AuthPin]);

  const getTimesheetsByStatus = (Timesheets: HotpayTimesheet[], Status: HotPayTimesheetStatus) => {
    return Timesheets.filter((Timesheet) => {
      return (Timesheet.Status === Status);
    });
  };

  const getTimesheetCountByStatus = (Timesheets: HotpayTimesheet[], Status: HotPayTimesheetStatus) => {
    return Timesheets.filter((Timesheet) => {
      return (Timesheet.Status === Status);
    }).length;
  };

  const handleSelectTimesheet = (Timesheet: HotpayTimesheet) => {
    handleTimesheet(Timesheet);
  };

  if(loadingInProgress){
    return (
      <LoadingIndicator />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height:"100%",
        width:"100%",
        maxWidth:"400px",
        margin:"auto"
      }}>
        <Stack>
          <Header />
          <AccordianGroup Title={`Approved (${getTimesheetCountByStatus(Timesheets, "APP")})`} Colour="#208637" {...(selectedAccordian === "APP" ? {
            Child: (
              <Box sx={{backgroundColor:"#EEEEEE",height:"calc(100vh - 287px)",width:"100%",overflowY:"scroll"}}>
                {getTimesheetsByStatus(Timesheets, "APP").map((Timesheet) => {
                  return (<TimesheetTile key={Timesheet.ID} Timesheet={Timesheet} handleClick={handleSelectTimesheet} />);
                })}
              </Box>
            ),
            handleClick: () => setSelectedAccordian(null)
          } : {
            handleClick: () => setSelectedAccordian("APP")
          })} />
          <AccordianGroup Title={`Unapproved (${getTimesheetCountByStatus(Timesheets, "UNAPP")})`} Colour="#B51F2E" {...(selectedAccordian === "UNAPP" ? {
            Child: (
              <Box sx={{backgroundColor:"#EEEEEE",height:"calc(100vh - 287px)",width:"100%",overflowY:"scroll"}}>
                {getTimesheetsByStatus(Timesheets, "UNAPP").map((Timesheet) => {
                  return (<TimesheetTile key={Timesheet.ID} Timesheet={Timesheet} handleClick={handleSelectTimesheet} />);
                })}
              </Box>
            ),
            handleClick: () => setSelectedAccordian(null)
          } : {
            handleClick: () => setSelectedAccordian("UNAPP")
          })} />
          <AccordianGroup Title={`Pending/Draft (${getTimesheetCountByStatus(Timesheets, "DRAFT")})`} Colour="#666666" {...(selectedAccordian === "DRAFT" ? {
            Child: (
              <Box sx={{backgroundColor:"#EEEEEE",height:"calc(100vh - 287px)",width:"100%",overflowY:"scroll"}}>
                {getTimesheetsByStatus(Timesheets, "DRAFT").map((Timesheet) => {
                  return (<TimesheetTile key={Timesheet.ID} Timesheet={Timesheet} handleClick={handleSelectTimesheet} />);
                })}
              </Box>
            ),
            handleClick: () => setSelectedAccordian(null)
          } : {
            handleClick: () => setSelectedAccordian("DRAFT")
          })} />
          <AccordianGroup Title={`Future (${getTimesheetCountByStatus(Timesheets, "FUT")})`} Colour="#BFBFBF" {...(selectedAccordian === "FUT" ? {
            Child: (
              <Box sx={{backgroundColor:"#EEEEEE",height:"calc(100vh - 287px)",width:"100%",overflowY:"scroll"}}>
                {getTimesheetsByStatus(Timesheets, "FUT").map((Timesheet) => {
                  return (<TimesheetTile key={Timesheet.ID} Timesheet={Timesheet} handleClick={handleSelectTimesheet} />);
                })}
              </Box>
            ),
            handleClick: () => setSelectedAccordian(null)
          } : {
            handleClick: () => setSelectedAccordian("FUT")
          })} />
        </Stack>
      </Box>
    </ThemeProvider>
  );

};
