import React from 'react';
import { ThemeProvider, createTheme, Stack, Grid, Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { UserContext } from '../contexts/user-context';
import { EmployerContext } from '../contexts/employer-context';
import { ProjectContext } from '../contexts/project-context';

const theme = createTheme({
  typography: {
    fontFamily: 'Varta',
  },
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

export const Header = () => {

  const User = React.useContext(UserContext);
  const Employer = React.useContext(EmployerContext);
  const Project = React.useContext(ProjectContext);

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Box sx={{borderBottom:"solid 1px #666666",height:40}}>
          <Grid container>
            <Grid item xs="auto">
              <img alt="HotPay" src="/hotpay_white_269x125.svg" height="30" style={{marginTop:"2px",marginLeft:"10px",marginRight:"5px"}} />
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <Typography sx={{lineHeight:"42px"}} variant="body1" color="primary" align="center">{User.Name}</Typography>
            </Grid>
            <Grid item xs="auto" sx={{paddingLeft:"5px",paddingRight:"5px"}}>
              <Box sx={{height:"100%",display:"flex",alignItems:"center"}}>
                <AccountCircleIcon color="primary" fontSize="large" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{borderBottom:"solid 1px #666666",backgroundColor:"#EEEEEE",height:40}}>
            <Typography variant="h6" align="center" sx={{lineHeight:'42px',fontWeight:'bold'}}>{Employer.Name}</Typography>
        </Box>
        <Box sx={{borderBottom:"solid 1px #666666",backgroundColor:"#EEEEEE",height:40}}>
            <Typography variant="h6" align="center" sx={{lineHeight:'42px',fontWeight:'bold'}}>{Project.Name}</Typography>
        </Box>
      </Stack>
    </ThemeProvider>
  );

};
