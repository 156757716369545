import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeProvider, createTheme, Stack, Box, TextField, Button, Typography } from '@mui/material';

import { LoadingIndicator } from '../components/loading-indicator';

const theme = createTheme({
  typography: {
    fontFamily: 'Sofia Sans, Varta',
  },
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#b22b2a"
    },
  }
});

interface LoginProps{
  handleLogin(AuthKey: string, AuthPin: string): void;
};

export const Login = ( {
  handleLogin
}: LoginProps ) => {

  const routeArgs = useParams();
  useEffect(() => {
    handleLogin(routeArgs.authkey || "", routeArgs.authpin || "");
  }, [handleLogin, routeArgs.authkey, routeArgs.authpin]);

  return (
    <LoadingIndicator />
  );

  // TODO: Re-implement interactive login
  /*return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height:"100%",
        width:"100%",
        maxWidth:"400px",
        margin:"auto"
      }}>
        <Stack>
          <Box className="hotpay_logo_container">
            <img alt="HotPay" src="/hotpay_white_269x125.svg" style={{maxWidth:"200px"}} />
            <Typography variant="body1" color="primary" align="center">Login to lodge timesheets</Typography>
          </Box>
          <Box className="hotpay_login_container">
            <Stack>
              <TextField label="Email Address" variant="outlined" className="hotpay_login_item" />
              <TextField label="Password" variant="outlined" className="hotpay_login_item" type="password" />
              <Button variant="contained" className="hotpay_login_item">Login</Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );*/

};
