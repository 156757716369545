import React from 'react';
import { HotpayTimesheet } from '../types/timesheet';
import { UserDefault } from './user-context';
import { EmployerDefault } from './employer-context';
import { ProjectDefault } from './project-context';

export const TimesheetDefault: HotpayTimesheet = {
  ID: "",
  User: UserDefault,
  Employer: EmployerDefault,
  Project: ProjectDefault,
  StartTime: new Date(),
  EndTime: null,
  Hours: null,
  Status: "DRAFT",
  Checksheets: [],
  Notes: [],
};

export const TimesheetContext = React.createContext<HotpayTimesheet>(TimesheetDefault);
