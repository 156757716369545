import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import { HotpayTimesheet } from './types/timesheet';

import { HotpayError, ErrorDefault, ErrorContext } from './contexts/error-context';
import { HotpayUser, UserDefault, UserContext } from './contexts/user-context';
import { HotpayEmployer, EmployerDefault, EmployerContext } from './contexts/employer-context';
import { HotpayProject, ProjectDefault, ProjectContext } from './contexts/project-context';
import { TimesheetDefault, TimesheetContext } from './contexts/timesheet-context';

import { ErrorScreen, ErrorProps } from './pages/error';
import { Login } from './pages/login';
import { TimesheetList } from './pages/timesheet-list';
import { TimesheetEntry } from './pages/timesheet-entry';

import './hotpay.css';

export const Hotpay = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [Error, setError] = React.useState<HotpayError>(ErrorDefault);
  const [User, setUser] = React.useState<HotpayUser>(UserDefault);
  const [Employer, setEmployer] = React.useState<HotpayEmployer>(EmployerDefault);
  const [Project, setProject] = React.useState<HotpayProject>(ProjectDefault);
  const [Timesheet, setTimesheet] = React.useState<HotpayTimesheet>(TimesheetDefault);

  useEffect(() => {
    const isLoginProcessing = (location.pathname.substring(0, 6) === "/login");
    if(!isLoginProcessing){
      const savedAuthKey = localStorage.getItem('hotpay-authkey');
      const savedAuthPin = localStorage.getItem('hotpay-authpin');
      if((!User.AuthKey || !User.AuthPin) && (savedAuthKey && savedAuthPin)){
        handleLogin(savedAuthKey, savedAuthPin);
      }
    }
  }, []);

  const handleError = ({errorTitle, errorText}: ErrorProps) => {
    setError({Title: errorTitle, Text: errorText});
    navigate('/error');
  };

  const handleEmployer = (Employer: HotpayEmployer) => {
    setEmployer(Employer);
  };

  const handleProject = (Project: HotpayProject) => {
    setProject(Project);
  };

  const handleTimesheet = (Timesheet: HotpayTimesheet) => {
    setEmployer(Timesheet.Employer);
    setProject(Timesheet.Project);
    setTimesheet(Timesheet);
    navigate('/timesheets/entry');
  };

  const handleLogin = (
    AuthKey: string,
    AuthPin: string,
  ) => {
    fetch("https://admin.hotpay.com.au/api/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'key='+AuthKey+'&pin='+AuthPin,
    }).then(response => response.json()).then(json => {
      if(!json.auth.ok){
        setError({
          Title: "Invalid Login",
          Text: "Please ask your Employer to resend your HotPay link.",
        });
        navigate('/error');
      }
      localStorage.setItem('hotpay-authkey', AuthKey);
      localStorage.setItem('hotpay-authpin', AuthPin);
      setUser({
        ID: json.auth.id,
        Name: json.auth.name,
        AuthKey: AuthKey,
        AuthPin: AuthPin,
      });
      navigate('/timesheets/list');
    });
  };

  return (
    <ErrorContext.Provider value={Error}>
    <UserContext.Provider value={User}>
    <EmployerContext.Provider value={Employer}>
    <ProjectContext.Provider value={Project}>
    <TimesheetContext.Provider value={Timesheet}>
      <Routes>
        <Route path="/login/:authkey/:authpin" element={<Login handleLogin={handleLogin} />} />
        <Route path="/timesheets/list" element={<TimesheetList handleError={handleError} handleEmployer={handleEmployer} handleProject={handleProject} handleTimesheet={handleTimesheet} />} />
        <Route path="/timesheets/entry" element={<TimesheetEntry handleError={handleError} />} />
        <Route path="/error" element={<ErrorScreen />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </TimesheetContext.Provider>
    </ProjectContext.Provider>
    </EmployerContext.Provider>
    </UserContext.Provider>
    </ErrorContext.Provider>
  );

};
