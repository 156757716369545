import React from 'react';

export interface HotpayProject{
  ID: string;
  Name: string;
};

export const ProjectDefault: HotpayProject = {
  ID: "",
  Name: "",
};

export const ProjectContext = React.createContext<HotpayProject>(ProjectDefault);
