import React from 'react';

export interface HotpayError{
  Title: string;
  Text: string;
};

export const ErrorDefault: HotpayError = {
  Title: "Something went wrong",
  Text: "Sorry, we can't do that at the moment. Please try again later.",
};

export const ErrorContext = React.createContext<HotpayError>(ErrorDefault);
