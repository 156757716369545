import React from 'react';

export interface HotpayUser{
  ID: string;
  Name: string;
  AuthKey?: string;
  AuthPin?: string;
};

export const UserDefault: HotpayUser = {
  ID: "",
  Name: "",
};

export const UserContext = React.createContext<HotpayUser>(UserDefault);
